<template>
  <!-- BEGIN: Display Information -->
  <div class="intro-y box lg:mt-5">
    <div
      class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="font-medium text-base mr-auto">
        Account settings
      </h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-12 xl:col-span-4">
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            <div
              class="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto"
            >
              <!--                            <img class="rounded-md" alt="Quant Lines" src="../../../../../dist/images/profile-7.jpg">-->
              <div
                title="Remove this profile photo?"
                class="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"
              >
                <i
                  data-feather="x"
                  class="w-4 h-4"
                />
              </div>
            </div>
            <div class="w-40 mx-auto cursor-pointer relative mt-5">
              <button
                type="button"
                class="button w-full bg-theme-1 text-white"
              >
                Change Photo
              </button>
              <input
                type="file"
                class="w-full h-full top-0 left-0 absolute opacity-0"
              >
            </div>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-8">
          <div>
            <label>Display Name</label>
            <!--                        <input type="text" :value="user.first_name + ' ' + user.last_name" class="input w-full border bg-gray-100 cursor-not-allowed mt-2" disabled>-->
          </div>
          <div class="mt-3">
            <label>Position</label>
            <div class="mt-2">
              <!--                            <select data-search="true" class="input border-gray-300 block w-full">-->
              <!--                                <option :value="position.id" v-for="position in positions" :key="position.id">-->
              <!--                                    {{ position.name }}-->
              <!--                                </option>-->
              <!--                            </select>-->
            </div>
          </div>
          <button
            type="button"
            class="button w-20 bg-theme-1 text-white mt-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Display Information -->
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import * as api from "@/plugins/api";

export default defineComponent({
  name: "PersonalInformation",
  setup() {
    const store = useStore();

    const settings = reactive({
      positions: [],
      user: computed(() => store.state.auth.user)
    });

    async function getPositions() {
      try {
        const { data } = await api.fetch({
          url: `/positions`,
          resource: "v1"
        });

        settings.positions = data;
      } catch (e) {
        console.log(e);
        settings.positions = [];
      }
    }

    getPositions();
    return {
      positions: computed(() => settings.positions),
      user: settings.user
    };
  }
});
</script>

<style scoped></style>
